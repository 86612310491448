import { takeLatest, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls } from '../lib/urls';
import Api from '../lib/request-service';
import { notification } from 'antd';

export default function* LogoutSaga() {
    yield takeLatest(types.LOGOUT_REQUEST, LogoutSagaApi);
}
function* LogoutSagaApi(params) {
    console.log(params);
    let param = { id: params.id, email: params.email };
    let history = params.history;
    try {
        const response = yield Api.post(`${api_urls.LOGOUT_USER}`, param, true);

        if (response?.status) {
            localStorage.removeItem('token');
            localStorage.removeItem('loggedInAs');
            localStorage.removeItem('user');
            notification.success({
                placement: 'topRight',
                message: response.message,
                maxCount: 1,
                duration: '1'
            });
            yield put({ type: types.SIGN_IN_UPDATE });
            yield put({ type: types.LOGOUT_SUCCESS, payload: response });
            history.push('/login');
        } else {
            yield put({ type: types.LOGOUT_FAILURE, error: response });
        }
        // notification error res.message
    } catch (error) {
        yield put({ type: types.LOGOUT_FAILURE, error: error });
        // notification error error.message
    }
}
