import { takeLatest, all, call, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls } from '../lib/urls';
import Api from '../lib/request-service';

export default function* GetAllAdminsSaga() {
    yield takeLatest(types.GET_ALL_ADMINS_REQUEST, GetAllAdminsSagaApi);
}
function* GetAllAdminsSagaApi() {
    // console.log(params);

    try {
        const response = yield Api.get(api_urls.GET_ALL_ADMINS, true);
        if (response.status) {
            yield put({ type: types.GET_ALL_ADMINS_SUCCESS, payload: response?.data });
            // notification success
        } else {
            yield put({ type: types.GET_ALL_ADMINS_FAILURE, error: response });
            // notification error res.message
        }
    } catch (error) {
        yield put({ type: types.GET_ALL_ADMINS_FAILURE, error: error });
        // notification error error.message
    }
}
