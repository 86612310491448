import { takeLatest, all, call, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls, variables } from '../lib/urls';
import Api from '../lib/request-service';
import { notification } from 'antd';

export default function* AddAdminSaga() {
    yield takeLatest(types.ADD_ADMIN_REQUEST, AddAdminSagaApi);
}
function* AddAdminSagaApi(params) {
    console.log('addADMINPARMS:: ', params);
    let param = {
        first_name: params.data.first_name,
        last_name: params.data.last_name,
        email: params.data.email,
        role: { ...params.data.roles },
        email_verified: true,
        password: params.data.password,
        user_type: params.data.user_type
    };

    try {
        const response = yield Api.post(`${api_urls.ADD_ADMIN}`, param, true);
        if (response.status === true) {
            yield put({ type: types.ADD_ADMIN_SUCCESS, payload: response });
            // notification success
            params.history.push('/admin');
            notification.success({
                placement: 'topRight',
                message: response.message
            });
        } else {
            yield put({ type: types.ADD_ADMIN_FAILURE, error: response });
            // notification error res.message
            notification.error({
                placement: 'topRight',
                message: response.message
            });
        }
    } catch (error) {
        yield put({ type: types.ADD_ADMIN_FAILURE, error: error });
        // notification error error.message
        notification.error({
            placement: 'topRight',
            message: error.message
        });
    }
}
