import { takeLatest, all, call, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls, variables } from '../lib/urls';
import Api from '../lib/request-service';
import { notification } from 'antd';

export default function* ChangePasswordSaga() {
    yield takeLatest(types.CHANGE_PASSWORD_REQUEST, ChangePasswordSagaApi);
}
function* ChangePasswordSagaApi(params) {
    let param = {
        old_password: params.current_password,
        password: params.new_password,
        confirm_password: params.confirm_password,
        user: params.user
    };
    let history = params.history;
    try {
        const response = yield Api.post(`${api_urls.CHANGE_PASSWORD}`, param, true);
        if (response.status === true) {
            yield put({ type: types.CHANGE_PASSWORD_SUCCESS, payload: response });
            // notification success
            notification.success({
                placement: 'topRight',
                message: response.message,
                duration: 1
            });
        } else {
            yield put({ type: types.CHANGE_PASSWORD_FAILURE, error: response });
            // notification error res.message
            notification.error({
                placement: 'topRight',
                message: response.message,
                duration: 1
            });
        }
    } catch (error) {
        yield put({ type: types.CHANGE_PASSWORD_FAILURE, error: error });
        // not error error.message
        notification.error({
            placement: 'topRight',
            // message: err.message,
            message: error.message,
            duration: 1
        });
    }
}
