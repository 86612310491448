import { takeLatest, all, call, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls, variables } from '../lib/urls';
import Api from '../lib/request-service';
import { notification } from 'antd';

export default function* ResetPasswordSaga() {
    yield takeLatest(types.RESET_PASSWORD_REQUEST, ResetPasswordSagaApi);
}
function* ResetPasswordSagaApi(params) {
    // console.log(params);
    // let param = params.payload;
    let param = { password: params.password, confirm_password: params.confirm_password, user: params.user, otp: params.otp };
    let history = params.history;
    try {
        const response = yield Api.post(`${api_urls.RESET_PASSWORD}`, param);
        if (response.status === true) {
            yield put({ type: types.RESET_PASSWORD_SUCCESS, payload: response });
            // notification success
            history.push('/login');
            notification.success({
                placement: 'topRight',
                message: response.message
            });
        } else {
            yield put({ type: types.RESET_PASSWORD_FAILURE, error: response });
            // notification error res.message
            notification.error({
                placement: 'topRight',
                message: response.message
            });
        }
    } catch (error) {
        yield put({ type: types.RESET_PASSWORD_FAILURE, error: error });
        // not error error.message
        notification.error({
            placement: 'topRight',
            // message: err.message,
            message: 'Error'
        });
    }
}
