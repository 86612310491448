import { takeLatest, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls } from '../lib/urls';
import Api from '../lib/request-service';
import { notification } from 'antd';
export default function* UpdateUserSaga() {
    yield takeLatest(types.UPDATE_USER_REQUEST, UpdateUserSagaApi);
}
function* UpdateUserSagaApi({params}) {
    console.log('updateuser saga:: ',params);
    try {
        const response = yield Api.put(api_urls.UPDATE_USER, params, true);
        if (response.status) {
            yield put({ type: types.UPDATE_USER_SUCCESS, payload: response?.data });
            // notification success
            notification.success({
                placement: "topRight",
                message: response.message,
            });
        } else {
            yield put({ type: types.UPDATE_USER_FAILURE, error: response });
            // notification error res.message
            notification.error({
                placement: "topRight",
                message: response.message,
            });
        }
    } catch (error) {
        yield put({ type: types.UPDATE_USER_FAILURE, error: error });
        // notification error error.message
    }
} 