import generator from 'generate-password'

export const passwordGenerator = () => {
    const password = generator.generate({
        length: 10,
        numbers: true,
        symbols: true,
        lowercase: true,
        uppercase: true,
        excludeSimilarCharacters: true,
        strict: true,
        exclude: "[]=|'`\""
    });

    return password;
}