import { takeLatest, all, call, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls, variables } from '../lib/urls';
import Api from '../lib/request-service';
import { notification } from 'antd';

export default function* AddClientSaga() {
    yield takeLatest(types.ADD_CLIENT_REQUEST, AddClientSagaApi);
}
function* AddClientSagaApi(params) {
    console.log('Params :::', params);
    let param = {
        first_name: params.first_name,
        last_name: params.last_name,
        company_name: params.company,
        profile_image: {
            base64: params.profile_image,
            type: params.imageType
        },
        email: params.email,
        password: params.password,
        user_type: params.user_type,
        // spokes: params.spokes,
        interest: params.interest
    };
    // console.log(param,"param Check")
    let history = params.history;
    try {
        const response = yield Api.post(`${api_urls.ADD_CLIENT}`, param, true);
        if (response.status === true) {
            yield put({ type: types.ADD_CLIENT_SUCCESS, payload: response });
            // notification success
            history.push('/clients/all-clients');
            notification.success({
                placement: 'topRight',
                message: response.message
            });
        } else {
            yield put({ type: types.ADD_CLIENT_FAILURE, error: response });
            // notification error res.message
            notification.error({
                placement: 'topRight',
                message: response.message
            });
        }
    } catch (error) {
        yield put({ type: types.ADD_CLIENT_FAILURE, error: error });
        // notification error error.message
        notification.error({
            placement: 'topRight',
            message: error.message
        });
    }
}
