import * as React from 'react';
import { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, Routes, useLocation } from 'react-router-dom';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import Loader from './layout/Loader';
import ScrollToTop from './layout/ScrollToTop';
import routesOnePage from '../route';
import subDomainRoutes from '../subDomainRoutes';
import routes from '../routes';
import { Redirect } from 'react-router-dom';
import Config from '../config';
import 'antd/dist/antd.css';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaign } from '../store/actions/getCampainActions';
import { useState } from 'react';
import { useRef } from 'react';
import {notificationFunction} from '../sockets/socket'
import {analytics as firebaseInitialization} from '../utils/firebaseInitialization';
import { getAnalytics, logEvent } from "firebase/analytics";

const HOST = process.env.REACT_APP_API_SUB_DOMAIN;
const AdminLayout = lazy(() => import('./layout/AdminLayout'));
const App = (props) => {
    let dispatch = useDispatch();

    const analytics = getAnalytics();
    logEvent(analytics, 'select_content', {
        content_type: 'image',
        content_id: 'P12453',
        items: [{ name: 'Kittens' }]
    });

    useEffect(() => {
        notificationFunction();
    }, []);

    useEffect(() => {
        subDomain();
    }, []);

    const subDomain = () => {
        let host = window.location.host;
        let hostArray = HOST.split(',');
        console.log('hostArray', hostArray);
        console.log('host', host);
        let parts = host.split('.');
        console.log('parts', parts);
        let expDomain = null;
        if(parts?.length > 1)  expDomain = parts.splice(0, 1)[0];
        let subDomain = null;
        let find = hostArray.findIndex((as) => as === expDomain);
        console.log(find,"find");
        if (find === -1) {
            subDomain = expDomain;
        } else if (parts[0] === expDomain) {
            subDomain = expDomain;
        }
        if (!!subDomain) {
            dispatch(getCampaign(subDomain));
        }else {
        }
    };
    // const { data } = useSelector((state) => state.authUserReducer);
    const data = localStorage.getItem('token');
    const location = useLocation();

    const getRedirection = () => {
        let host = window.location.host;
        let hostArray = HOST.split(',');
        let parts = host.split('.');
        if(parts.length<2)
            return false
        let expDomain = parts.splice(0, 1)[0];
        let subDomain = null;
        if (hostArray.findIndex((as) => as === expDomain) === -1) {
            subDomain = expDomain;
        } else if (parts[0] === expDomain) {
            subDomain = expDomain;
        }
        if ((!data || data)  && !!subDomain && typeof subDomain != null) {
            return true;
        } else {
            return false;
        }
    };
    return (<>
        <ScrollToTop>
            <Suspense fallback={<Loader />}>
                {getRedirection() ?
                    <Switch>
                        {/*this will be the login check for specific campaign*/}
                        <Route path={subDomainRoutes.map((x) => x.path)}>
                            <Switch location={location} key={location.pathname}>
                                {subDomainRoutes.map((route, index) => {
                                    return route.component ? (
                                        <Route key={index} path={route.path} exact={route.exact}
                                               render={(props) => <route.component {...props} />} />) : null;
                                })}
                            </Switch>
                        </Route>
                    </Switch>
                    :
                    <Switch>
                        {!data && (
                            <Route path={routesOnePage.map((x) => x.path)}>
                                <Switch location={location} key={location.pathname}>
                                    {routesOnePage.map((route, index) => {
                                        return route.component ? (
                                            <Route key={index} path={route.path} exact={route.exact}
                                                   render={(props) =>
                                                       <route.component {...props} />} />) : null;
                                    })}
                                </Switch>
                            </Route>
                        )}
                        {data && (
                            <Route path={routes.map((x) => x.path)}>
                                <AdminLayout />
                            </Route>
                        )}
                        <Route path='*' exact>
                            {getRedirection() ? <Redirect to={'/'} /> : <Redirect to={data ? '/overview' : '/login'} />}
                        </Route>
                    </Switch>
                }
            </Suspense>
        </ScrollToTop>
        <div className='backdrop' />
    </>);
};
export default App;
