import React, { useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { TextInput, ValidationForm } from 'react-bootstrap4-form-validation';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { doAddAdminRole } from '../../store/actions/user.actions';
const permissionArr = {
    adminManagement: false,
    campaignManagement: false,
    accountManagement: false
};
const AddRole = () => {
    const [data, setData] = useState({
        // notes: [],
        // showTape: false,
        // showOutput: true,
        // showTitle: true,
        // showFooter: true,
        // output: '',
        // colors: ['#FFFFFF'],
        // showCustomColors: false,
        // showMock: false,
        // showBound: false
        name: '',
        role_type: 'ADMIN',
        permission: permissionArr,
    });
    let history = useHistory();
    const handleChangePermissions = (e) => {

        setData((data) => ({ ...data, permission: {
            ...data.permission,
            
            [e]:!data["permission"][e] 
           
          }}));
        //   console.log(data,"check")
    };
    const dispatch = useDispatch();
    const toggleValue = (e) => {
        // @ts-ignore
        setData({ ...data, [e.target.name]: !data[e.target.name] });
    };
    let wrapperStyle = {};
    if (data.showBound) {
        wrapperStyle = {
            height: '700px',
            width: '700px',
            background: 'rgba(0, 0, 0, 0.2)',
            border: '2px solid #fff',
            overflow: 'auto',
            padding: '10px'
        };
    }
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    const handleSubmit = (e, formData, inputs) => {
        e.preventDefault();
        setData(data);

        dispatch(doAddAdminRole(data, history));
        // console.log(data);
    };
    const handleErrorSubmit = (e, formData, errorInputs) => {
        //console.log(errorInputs);
    };
    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title as="h5">Add New Role</Card.Title>
                </Card.Header>
                <Card.Body>
                    <ValidationForm onSubmit={handleSubmit} onErrorSubmit={handleErrorSubmit}>
                        <Row>
                            <Col xl={3}>
                                <div className="form-group fill">
                                    <label htmlFor="name">Name</label>
                                    <TextInput
                                        name="name"
                                        id="name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        required
                                        value={data.name}
                                        onChange={handleChange}
                                        autoComplete="off"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card.Title as="h5">Allow Permissions</Card.Title>
                                {/* <fieldset> */}
                                <fieldset>
                                    <Form.Group as={Row}>
                                        <Col md={3}>
                                            {/* {permissionArr.map((sp,index) => ( */}
                                            {Object.entries(permissionArr).map(([key, value]) => (
                                                <Form.Check
                                                    type="checkbox"
                                                    id={key}
                                                    label={key}
                                                    name={key}
                                                    onChange={(e) => handleChangePermissions(key, value)}
                                                />
                                            ))}
                                            {/* {Object.keys((permissionArr) => ( 
                                                <Form.Check
                                                    type="checkbox"
                                                    // label={sp}
                                                    // name={sp+index}
                                                    // id={'formHorizontalRadios2' + sp + index}
                                                    // onChange={(e) => handleChangePermissions(permission)}
                                                />
                                            ))} */}
                                        </Col>
                                    </Form.Group>
                                </fieldset>
                                <button className="btn btn-block btn-turquoise">Submit</button>
                            </Col>
                        </Row>
                    </ValidationForm>
                </Card.Body>
            </Card>
        </>
    );
};

export default AddRole;
