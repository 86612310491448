import types from '../types/types';
const initialState = {
    data: null,
};

const requestState = {
    ...initialState,
    loading: true,
};
 const update_client_states = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_CLIENT_STATES:
            console.log(action.payload,"reducer")
            return {
                ...state,
                data:{...state.data, ...action.payload},
            };
        case types.SIGN_IN_UPDATE:
            return {
                ...state,
                status: null,
                message: null,
                error: null,
                data: null,
                loading: false
            };
        default:
            return state;
    }
};

export default update_client_states;
