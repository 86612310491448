import types from '../types/types';
const initialState = {
    user: {},
    error: '',
    data: null,
    isLoading: false
};
const authUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SIGN_IN_REQUEST:
            return {
                ...state
            };
        case types.SIGN_IN_SUCCESS:
            return {
                ...state,
                status: 200,
                message: 'SUCCESS',
                error: null,
                data: action.payload.data,
                loading: false
            };
        case types.SIGN_IN_FAILURE:
            return {
                ...state,
                status: action.error.status,
                message: action.error.message,
                error: action.error,
                data: null,
                loading: false
            };
        case types.SIGN_IN_UPDATE:
            return {
                ...state,
                status: null,
                message: null,
                error: null,
                data: null,
                loading: false
            };

        // 2nd Approach
        case types.FORGOT_PASSWORD_REQUEST:
            return {
                ...state
            };
        case types.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                status: 200,
                message: 'SUCCESS',
                error: null,
                data: action.payload.data,
                loading: false
            };
        case types.FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                status: action.error.status,
                message: action.error.message,
                error: action.error,
                data: null,
                loading: false
            };
        case types.FORGOT_PASSWORD_UPDATE:
            return {
                ...state,
                status: null,
                message: null,
                error: null,
                data: null,
                loading: false
            };
        // Reset Password
        case types.RESET_PASSWORD_REQUEST:
            return {
                ...state
            };
        case types.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                status: 200,
                message: 'SUCCESS',
                error: null,
                data: action.payload.data,
                loading: false
            };
        case types.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                status: action.error.status,
                message: action.error.message,
                error: action.error,
                data: null,
                loading: false
            };
        case types.RESET_PASSWORD_UPDATE:
            return {
                ...state,
                status: null,
                message: null,
                error: null,
                data: null,
                loading: false
            };
        // Change Password
        case types.CHANGE_PASSWORD_REQUEST:
            return {
                ...state
            };
        case types.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                status: 200,
                message: 'SUCCESS',
                error: null,
                data: action.payload.data,
                loading: false
            };
        case types.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                status: action.error.status,
                message: action.error.message,
                error: action.error,
                data: null,
                loading: false
            };
        case types.CHANGE_PASSWORD_UPDATE:
            return {
                ...state,
                status: null,
                message: null,
                error: null,
                data: null,
                loading: false
            };
        // Logout
        // case types.LOGOUT_REQUEST:
        //     return {
        //         ...state
        //     };
        // case types.LOGOUT_SUCCESS:
        //     return {
        //         ...state,
        //         status: 200,
        //         message: 'SUCCESS',
        //         error: null,
        //         data: null,
        //         loading: false
        //     };
        // case types.LOGOUT_FAILURE:
        //     return {
        //         ...state,
        //         status: action.error.status,
        //         message: action.error.message,
        //         error: action.error,
        //         data: null,
        //         loading: false
        //     };
        // case types.LOGOUT_UPDATE:
        //     return {
        //         ...state,
        //         status: null,
        //         message: null,
        //         error: null,
        //         data: null,
        //         loading: false
        //     };
            // Get user by Id
        //     case types.GET_USER_BY_ID_REQUEST:
        //     return {
        //         ...state
        //     };
        // case types.GET_USER_BY_ID_SUCCESS:
        //     return {
        //         ...state,
        //         status: 200,
        //         message: 'SUCCESS',
        //         error: null,
        //         data: null,
        //         loading: false
        //     };
        // case types.GET_USER_BY_ID_FAILURE:
        //     return {
        //         ...state,
        //         status: action.error.status,
        //         message: action.error.message,
        //         error: action.error,
        //         data: null,
        //         loading: false
        //     };
        // case types.GET_USER_BY_ID_UPDATE:
        //     return {
        //         ...state,
        //         status: null,
        //         message: null,
        //         error: null,
        //         data: null,
        //         loading: false
        //     };
        default: {
            return state;
        }
    }
};
export default authUserReducer;
