import types from '../types/types';
export const doSignIn = (params, history) => {
    return {
        type: types.SIGN_IN_REQUEST,
        payload: params,
        history: history,
    };
};

export const doForgetPassword = (params, history) => ({
    type: types.FORGOT_PASSWORD_REQUEST,
    payload: params,
    history: history
});

export const doResetPassword = (params, history) => {
    // console.log(params)
    return {
        type: types.RESET_PASSWORD_REQUEST,
        ...params,
        history: history
    };
};

export const doChangePassword = (params,history) => {
    // console.log(params)
    return {
        type: types.CHANGE_PASSWORD_REQUEST,
        ...params,
        history: history
    };
};

// Add Client
export const doAddClient = (params,history) => {
    return {
        type: types.ADD_CLIENT_REQUEST,
        ...params,
        history:history
    };
};

export const updateStateForAddClient = (params,history) => {
    return {
        type: types.ADD_CLIENT_STATES,
        payload: params,
        history:history
    };
};

// Add Admin
export const doAddAdmin = (params) => {
    return {
        type: types.ADD_ADMIN_REQUEST,
        ...params
    };
};
// Get All Clients
export const doGetAllClients = (params) => {
    return {
        type: types.GET_ALL_CLIENTS_REQUEST
        // ...params
    };
};
// Get All Fans
export const doGetAllFans = (params) => {
    return {
        type: types.GET_ALL_FANS_REQUEST
        // ...params
    };
};
// Get all Admins
export const doGetAllAdmins = () => {
    return {
        type: types.GET_ALL_ADMINS_REQUEST
    };
};
// Delete Admin
export const doDeleteAdmin = (params) => {
    return {
        type: types.DEL_ADMIN_REQUEST,
        params
    };
};
// Logout
export const doLogout = (params, history) => {
    return {
        type: types.LOGOUT_REQUEST,
        ...params,
        history: history
    };
};

// Logout
export const doLogoutUpdate = () => {
    return {
        type: types.LOGOUT_UPDATE,
    };
};
// Get User by id
export const doGetUserById = (params) => {
    return {
        type: types.GET_USER_BY_ID_REQUEST,
        params,
    };
};
// View User by id
export const doViewUserById = (params) => {
    return {
        type: types.VIEW_USER_BY_ID_REQUEST,
        params,
    };
};
// Update Admin
export const doUpdateAdmin = (params, history) => {
    console.log('history:: ',history)
    return {
        type: types.UPDATE_ADMIN_REQUEST,
        ...params,
        history: history,
    };
};
// Update User
export const doUpdateUser = (params) => {
    return {
        type: types.UPDATE_USER_REQUEST,
        params,
    };
};
// Add Role
export const doAddAdminRole = (params, history) => {
    return {
        type: types.ADD_ADMIN_ROLE_REQUEST,
        ...params,
        history:history
    };
};
// Admin Update Role
export const doUpdateAdminRole = (params, history) => {
    return {
        type: types.ADMIN_UPDATE_ROLE_REQUEST,
        ...params,
        history:history
    };
};
// Resend Forgot Password Email
// export const doResendEmail = (params, history) => ({
//     type: types.FORGOT_PASSWORD_REQUEST,
//     payload: params,
//     history: history
// });
// OTP Validation
export const doOtpValidation = (params) => {
    return {
        type: types.OTP_VALIDATION_REQUEST,
        ...params,
    };
};

export const clearAuthStates = () => {
    return {
        type: types.SIGN_IN_UPDATE,
    };
};

export const signUpUserDomain = (params) => {
    return {
        type: types.SIGN_UP_REQUEST_DOMAIN,
       payload: params,
    };
};

export const doSignInDomain = (params) => {
    return {
        type: types.SIGN_IN_REQUEST_DOMAIN,
        payload: params,
    };
};

// Verify Email
export const doVerifyEmail = (params) => {
    console.log(params)
    return {
        type: types.VERIFY_EMAIL_REQUEST,
        ...params,
    };
};

// Resend Verification Email
export const doResendVerifyEmail = (params) => {
    return {
        type: types.RESEND_VERIFY_EMAIL_REQUEST,
        params,
    };
};

// Resend Verification Email
export const resetSignInDomain = () => {
    return {
        type: types.SIGN_IN_UPDATE_DOMAIN,
    };
};

// Send Contact form
// export const sendContactFrom = (params) => {
//     return {
//         type: types.CONTACT_FORM,
//         ...params,
//     };
// };
