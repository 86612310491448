import {takeLatest, call, put} from 'redux-saga/effects';
import types from '../types/types';
import Api from '../lib/request-service';
import {api_urls, variables} from '../lib/urls';

export default function* createCampaign() {
    yield takeLatest(types.CREATE_CAMPAIGN_REQUEST, createCampaignApi);
}
function* createCampaignApi(params) {
    let param = params.payload;
    console.log(param);
    try {
        const response = yield Api.post(`${api_urls.CREATE_CAMPAIGN}`, param, true,false);
        if (response.status === true) {
            yield put({type: types.CREATE_CAMPAIGN_SUCCESS, payload: response});
        } else {
            yield put({type: types.CREATE_CAMPAIGN_FAILURE, error: response});
        }
    } catch (error) {
        yield put({type: types.CREATE_CAMPAIGN_FAILURE, error: error});
    }
}
