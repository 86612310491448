import types from '../types/types';
const initialState = {
    show: false,
};

const requestState = {
    ...initialState,
    loading: true
};
const show_bubble = (state = initialState, action) => {
    switch (action.type) {
        case types.SHOW_BUBBLE:
            return {
                ...state,
                show: action.payload
            };
        case types.EMPTY_BUBBLE:
            return {
                ...initialState,
                show: false,
            };
        case types.SIGN_IN_UPDATE:
            return {
                ...state,
                status: null,
                message: null,
                error: null,
                data: null,
                loading: false
            };
        default:
            return state;
    }
};

export default show_bubble;
