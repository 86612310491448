import * as React from 'react';
const Signin = React.lazy(() => import('./components/Authentication/SignIn/SignIn'));
const ForgotPassword = React.lazy(() => import('./components/Authentication/ResetPassword/ForgotPassword'));
const ResetEmail = React.lazy(() => import('./components/Authentication/ResetPassword/ForgotResetEmail'));
const ResetPassword = React.lazy(() => import('./components/Authentication/ResetPassword/ResetPassword'));
const ChangeForgotPassword = React.lazy(() => import('./components/Authentication/ResetPassword/ChangeForgotPassword'));
const SessionExpired = React.lazy(() => import('./components/Authentication/SessionExpired'));
const ProfileSettings = React.lazy(() => import('./components/Authentication/ProfileSettings'));
const TabsAuth = React.lazy(() => import('./components/Authentication/TabsAuth'));
const ChangePassword = React.lazy(() => import('./components/Settings/ChangePassword'));
const Error = React.lazy(() => import('./components/Maintenance/Error'));
const OfflineUI = React.lazy(() => import('./Demo/Maintenance/OfflineUI'));
const ComingSoon = React.lazy(() => import('./components/Maintenance/ComingSoon'));
// const GenericTemplate = React.lazy('./components/Campaigns/CreateCampaign/templates/Generic/GenericTemplate');

const route = [

    { path: '/login', exact: true, name: 'Signin', component: Signin },
    { path: '/forgot-password', exact: true, name: 'Forgot Password', component: ForgotPassword },
    { path: '/forgot-reset-email', exact: true, name: 'Forgot Reset Email', component: ResetEmail },
    { path: '/reset-password/:user/:otp', exact: true, name: 'Reset Password', component: ResetPassword },
    { path: '/auth/change-forgot-password/:user/:otp', exact: true, name: 'Change Forgot Password', component: ChangeForgotPassword },
    { path: '/session-expired', exact: true, name: 'Session Expired', component: SessionExpired },
    // { path: '/auth/profile-settings', exact: true, name: 'Profile Settings', component: ProfileSettings },
    // { path: '/auth/tabs-auth', exact: true, name: 'Tabs Authentication', component: TabsAuth },
    // { path: '/maintenance/error', exact: true, name: 'Error', component: Error },
    // { path: '/maintenance/coming-soon', exact: true, name: 'Coming Soon', component: ComingSoon },
    // { path: '/maintenance/offline-ui', exact: true, name: 'Offline UI', component: OfflineUI }
];
export default route;
