import * as React from 'react';
import { useSelector } from 'react-redux';
import AddAdmin from './components/Admin/AddAdmin';
import AddRole from './components/Roles/AddRole';
const GenericTemplate = React.lazy('./components/Campaigns/CreateCampaign/templates/Generic/GenericTemplate');
// const DashboardDefault = React.lazy(() => import('./Demo/Dashboard/Default'));
const UsersProfile = React.lazy(() => import('./Demo/Users/UserProfile'));
const UsersCards = React.lazy(() => import('./Demo/Users/UserCards'));
const UsersList = React.lazy(() => import('./Demo/Users/UserList'));
const Overview = React.lazy(() => import('./components/Overview/Overview'));
const Clients = React.lazy(() => import('./components/Clients/Clients'));
const Fans = React.lazy(() => import('./components/Fans/Fans'));
// const AddClientStepper = React.lazy(() => import('./components/Clients/AddClientStepper'));
const AddClientStepper = React.lazy(() => import('./components/Clients/AddNewClient'));
const ClientProfile = React.lazy(() => import('./components/Clients/ClientProfile'));
const EditClient = React.lazy(() => import('./components/Clients/EditClient'));
const TempIndex = React.lazy(() => import('./components/Campaigns/CreateCampaign/TempIndex'));
const TempIndexEdit = React.lazy(() => import('./components/Campaigns/EditCampaign/TempIndexEdit'));
const TemplateReview = React.lazy(() => import('./components/Campaigns/CreateCampaign/templates/Generic/TemplateReview'));
const CampaignPublish = React.lazy(() => import('./components/Campaigns/CreateCampaign/templates/CampaignReady'));
const CampaignPublishEdit = React.lazy(() => import('./components/Campaigns/EditCampaign/templates/CampaignReadyEdit'));
const CampaignHub = React.lazy(() => import('./components/Campaigns/CampaignHub'));
const PendingCampaigns = React.lazy(() => import('./components/Campaigns/PendingCampaigns'));
const Drafts = React.lazy(() => import('./components/Campaigns/Drafts'));
const Admin = React.lazy(() => import('./components/Admin/Admins'));
const ChangePassword = React.lazy(() => import('./components/Settings/ChangePassword'));
const AddNewAdmin = React.lazy(() => import('./components/Admin/AddAdmin'));
const AdminProfile = React.lazy(() => import('./components/Admin/AdminProfile'));
const EditAdmin = React.lazy(() => import('./components/Admin/EditAdmin'));
const AddNewRole = React.lazy(() => import('./components/Roles/AddRole'));
const RolesPermissions = React.lazy(() => import('./components/Admin/RolesPermissions'));
const CampaignDashboard = React.lazy(() => import('./components/Campaigns/CampaignDashboard'));
const AddNewPlan = React.lazy(() => import('./components/Plan/AddNewPlan'));
const ViewAllPlans = React.lazy(() => import('./components/Plan/ViewAllPlans'));
const AffiliatePrograms = React.lazy(() => import('./components/AffiliateMarketing/AffiliatePrograms'));
const AffiliateTransactions = React.lazy(() => import('./components/AffiliateMarketing/AffiliateTransactions'));
const Coupons = React.lazy(() => import('./components/Coupons/Coupons'));
const Contact = React.lazy(() => import('./components/Contact/Contact'));
const TermsConditions = React.lazy(() => import('./components/TermsConditions/TermsConditions'));
const PrivacyPolicy = React.lazy(() => import('./components/PrivacyPolicy/PrivacyPolicy'));
const CookiesPolicy = React.lazy(() => import('./components/CookiesPolicy/CookiesPolicy'));
const Notifications = React.lazy(() => import('./components/Notification/Notification'));
const SystemConfiguration = React.lazy(() => import('./components/Settings/SystemConfiguration'));
const Reports = React.lazy(() => import('./components/Reports/Reports'));

// const  {token} = useSelector((state) => state.authUserReducer);
// localStorage.getItem('token')

const routes = [
    { path: '/overview', exact: true, name: 'Overview', component: Overview },
    { path: '/change-password', exact: true, name: 'Change Password', component: ChangePassword },
    { path: '/clients/all-clients',  name: 'Clients', component: Clients },
    { path: '/fans/all-fans',  name: 'Fans', component: Fans },
    { path: '/clients/add-client', exact: true, name: 'Add Clients', component: AddClientStepper },
    { path: '/clients/client-profile', exact: true, name: 'Client Profile', component: ClientProfile },
    { path: '/clients/edit-client', exact: true, name: 'Client Profile', component: EditClient },
    { path: '/campaigns/create-new', exact: true, name: 'Create New', component: TempIndex },
    { path: '/campaigns/edit-campaign', exact: true, name: 'Edit Campaign', component: TempIndexEdit },
    { path: '/campaigns/review', exact: true, name: 'Review', component: TemplateReview },
    { path: '/campaigns/review-edit', exact: true, name: 'Review', component: TemplateReview },
    { path: '/campaigns/publish', exact: true, name: 'Campaign Ready', component: CampaignPublish },
    { path: '/campaigns/publish-edit', exact: true, name: 'Campaign Ready', component: CampaignPublishEdit },
    { path: '/campaigns/campaign-hub', exact: true, name: 'Campaign Hub', component: CampaignHub },
    { path: '/campaigns/pending-campaigns', exact: true, name: 'Pending Campaigns', component: PendingCampaigns },
    { path: '/campaigns/drafts', exact: true, name: 'Draft Campaigns', component: Drafts },
    { path: '/admin', exact: true, name: 'Admin', component: Admin },
    { path: '/admin/add-new-admin', exact: true, name: 'Add New Admin', component: AddNewAdmin },
    { path: '/profile', exact: true, name: 'Profile', component: AdminProfile },
    { path: '/admin/edit-admin', exact: true, name: 'Edit Admin', component: EditAdmin },
    { path: '/roles/roles-permissions', exact: true, name: 'Roles & Permissions', component: RolesPermissions },
    { path: '/roles/add-new-role', exact: true, name: 'Add New Role', component: AddNewRole },
    { path: '/campaigns/campaign-dashboard', exact: true, name: 'Campaign Dashboard', component: CampaignDashboard },
    { path: '/pricing/create-new', exact: true, name: 'Add New Plan', component: AddNewPlan },
    { path: '/pricing/view-all', exact: true, name: 'View All Plans', component: ViewAllPlans },
    { path: '/affiliate-programs', exact: true, name: 'Affiliate Programs', component: AffiliatePrograms },
    { path: '/affiliate-transactions', exact: true, name: 'Affiliate Transactions', component: AffiliateTransactions },
    { path: '/coupons', exact: true, name: 'Coupons', component: Coupons },
    { path: '/contact', exact: true, name: 'Contact', component: Contact },
    { path: '/terms-conditions', exact: true, name: 'Terms & Conditions', component: TermsConditions },
    { path: '/privacy-policy', exact: true, name: 'Privacy Policy', component: PrivacyPolicy },
    { path: '/cookies-policy', exact: true, name: 'Cookies Policy', component: CookiesPolicy },
    { path: '/notifications', exact: true, name: 'Notifications', component: Notifications },
    { path: '/config', exact: true, name: 'SystemConfiguration', component: SystemConfiguration },
    { path: '/reports', exact: true, name: 'Reports', component: Reports },
    // { path: '/campaign/view', exact: true, name: 'Generic', component: GenericTemplate },
    // { path: '/dashboard/default', exact: true, name: 'Analytics', component: DashboardDefault },
];
export default routes;
