import { combineReducers } from 'redux';
import { createSelectorHook } from 'react-redux';
import ableReducer from './ableReducer';
import demoReducer from './demoReducer';
import  authUserReducer from "./reducer/userReducers"
import create_campaign from "./reducer/createCampaign"
import update_campaign_states from "./reducer/updateCampaignStates"
import add_client from './reducer/addClientReducer';
import update_client_states from './reducer/updateStatesForAddClient';
import get_allClients from './reducer/getAllClientsReducer';
import get_allFans from './reducer/getAllFansReducer';
import get_allAdmins from './reducer/getAllAdminsReducer';
import get_userById from './reducer/getUserById';
import view_userById from './reducer/viewUserById';
import otp_validationReducer from './reducer/otpValidationReducer';
import get_campaign from './reducer/getCampaign';
import domainAuthReducer from './reducer/domainAuthReducer';
import signInDomainReducer from './reducer/signInDomain';
import verifyEmailReducer from './reducer/verifyEmailReducer';
import logoutReducer from './reducer/logoutReducer'
import show_bubble from './reducer/showBuuble'
import edit_campaign_states from './reducer/editCampaignReducer'
import get_edit_campaign from './reducer/getCampaignEditReducer'
import update_campaign from './reducer/updateCamapignReducer'
const reducer = combineReducers({
    able: ableReducer,
    demo: demoReducer,
    add_client: add_client,
    authUserReducer: authUserReducer,
    create_campaign:create_campaign,
    update_campaign_states:update_campaign_states,
    update_client_states:update_client_states,
    get_allClients:get_allClients,
    get_allFans:get_allFans,
    get_allAdmins:get_allAdmins,
    get_userById: get_userById,
    view_userById: view_userById,
    otp_validationReducer: otp_validationReducer,
    get_campaign: get_campaign,
    domainAuthReducer: domainAuthReducer,
    signInDomainReducer: signInDomainReducer,
    verifyEmailReducer: verifyEmailReducer,
    logoutReducer: logoutReducer,
    show_bubble: show_bubble,
    edit_campaign_states: edit_campaign_states,
    get_edit_campaign: get_edit_campaign,
    update_campaign: update_campaign,
});
export const useSelector = createSelectorHook();
export default reducer;
