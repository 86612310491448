export const api_urls = {
    CREATE_CAMPAIGN: '/campaign/create',
    REVIEW_CAMPAIGN: '/campaign/review',
    GET_ALL_CAMPAIGNS: '/campaign/get-all',
    GET_ALL_USERS_BY_TYPE: '/admin/get-user-by-type',
    SIGN_IN: '/admin/sign-in',
    GET_ALL_TEMPLATES: '/template/get-default-templates',
    FORGOT_PASSWORD: '/admin/forgot-password',
    RESET_PASSWORD: '/admin/reset-password',
    CHANGE_PASSWORD: '/admin/change-password',
    ADD_CLIENT: '/admin/add-client',
    ADD_ADMIN: '/admin/add-admin',
    GET_ALL_CLIENTS: '/admin/get-all-clients',
    GET_ALL_ADMINS: '/admin/get-user-by-type/ADMIN',
    DEL_ADMIN: '/admin/delete-admin',
    LOGOUT_USER: '/admin/sign-out',
    GET_USER_BY_ID: '/admin/get-user-by-id',
    UPDATE_ADMIN: '/admin/update-admin',
    UPDATE_USER: '/user/update-user',
    ADMIN_ADD_ROLE: '/admin/roles/create',
    ADMIN_DELETE_ROLE: '/admin/roles/delete',
    GET_PRESIGNED_URL: '/s3/get-presignedUrl',
    ADMIN_UPDATE_ROLE: '/admin/roles/update',
    OTP_VALIDATION: '/admin/otp-validation',
    GET_INTERESTS: '/interest/get-all-interests',
    DELETE_USER: '/user/delete-user',
    GET_ALL_ROLES: '/admin/roles/get-all-roles',
    UPDATE_ROLE: '/admin/roles/update',
    GET_USER_CAMPAIGNS: '/campaign/get-campaign-by-user',
    GET_ALL_ROLES_FOR_DROPDOWN: '/admin/roles/get-all-roles-for-dropdown',
    GET_ALL_PERMISSIONS: '/admin/roles/get-all-permissions',
    GET_CAMPAIGN_BY_DOMAIN: '/campaign/get-campaign-by-domain',
    DELETE_CAMPAIGN: '/campaign/delete',
    UPDATE_CAMPAIGN: '/campaign/update',
    UPDATE_CAMPAIGN_STATUS: '/campaign/update/status',
    GET_CAMPAIGN_BY_ID: '/campaign/get-campaign-by-id',
    GET_CAMPAIGN_BY_STATUS: '/campaign/get-campaign-by-status/',
    BLOCK_USER: '/admin/block-unblock-user',
    GET_COUNTRIES: '/others/get-countries',
    CHECK_AVAILABILITY: '/admin/check-credentials-availability',
    DOMAIN_USER_SIGN_UP: '/user/sign-up-user',
    DOMAIN_USER_SIGN_IN: '/user/sign-in',
    VERIFY_EMAIL: '/user/email-verification',
    RESEND_VERIFY_EMAIL: '/user/resend-web-email-verification',
    CREATE_PLAN: '/plans/create',
    GET_PLAN_BY_ID: '/plans/get-plan-by-id/:id',
    GET_ALL_PLANS: '/plans/get-all-plans',
    UPDATE_PLAN: '/plans/update',
    DELETE_PLAN: '/plans/delete',
    SOCIAL_SHARE_CAMPAIGN: '/campaign/share',
    CREATE_COUPON: '/coupons/create',
    GET_COUPON_BY_ID: '/coupons/get-coupon-by-id/:id',
    GET_ALL_COUPONS: '/stripe/coupons-list',
    UPDATE_COUPON: '/coupons/update',
    DELETE_COUPON: '/coupons/delete',
    GET_ALL_ACTIVE_CAMPAIGNS_COUNT: '/stats/get-active-campaigns-count',
    GET_ACTIVE_CLIENTS_COUNT: '/stats/get-active-clients-count',
    GET_ACTIVE_FANS_COUNT: '/stats/get-active-fans-count',
    GET_ACTIVE_CAMPAIGNS_BY_USER: '/campaign/get-active-campaigns-by-user-id/',
    GET_ACTIVE_CAMPAIGNS: '/campaign/get-active-campaigns',
    GET_RECENT_CAMPAIGNS: '/campaign/get-recent-campaigns',
    SEND_CONTACT_FORM: '/others/contact-us-email',
    GET_FOLLOW_LIST: '/user/get-followers-by-user-id',
    CREATE_TEMP_CAMPAIGN: '/campaign/create-temp-campaign',
    UPDATE_TEMP_CAMPAIGN: '/campaign/update-temp-campaign',
    GET_TEMP_CAMPAIGN: '/campaign/get-temp-campaign-by-id',
    DELETE_TEMP_CAMPAIGN: '/campaign/delete-temp-campaign',
    GET_TEMP_CAMPAIGN_BY_DOMAIN: '/campaign/get-temp-campaign-by-domain',
    USER_FORGOT_PASSWORD: '/user/web-forgot-password',
    USER_RESET_PASSWORD: '/user/reset-password',
    VERIFY_USER: '/user/verify-user',
    GET_TOP_INTEREST: '/stats/get-top-interest',
    VISITOR_STAT: '/stats/set-viewers-stats',
    TOP_INFLUENCERS: '/stats/get-top-influencers?value=',
    CAMPAIGN_DASHBOARD_STATS_BY_ID: '/stats/get-campaign-stats',
    CAMPAIGN_INFLUENCER_TREE_STATS_BY_ID: '/stats/get-influencer-tree-by-campaign',
    GET_LOCATION_STATS: '/stats/get-campaign-stats-by-location',
    GET_SIGN_UP_STATS_BY_DATE: '/stats/get-campaign-sign-ups-by-date',
    GET_SHARE_STATS_BY_DATE: '/stats/get-campaign-share-by-date',
    CAMPAIGN_UNIQUENESS: '/campaign/check-campaign-uniqueness',
    GET_NOTIFICATIONS_BY_USER: '/others/get-notification-by-user',
    MARK_READ_NOTIFICATION: '/others/mark-read-notification',
    VALIDATE_COUPON: '/stripe/check-coupon/',
    CREATE_BILL: '/payments/create-bill',
    CREATE_CARD_TOKEN: '/payments/create-card-token',
    HASHTAG_API: '/others/get-hashtags',
    GET_ALL_REVENUE_SETTINGS: '/revenue-settings/get-all-revenue-settings',
    GET_DEFAULT_REVENUE_SETTINGS: '/revenue-settings/get-default-revenue-settings',
    CREATE_USER_REVENUE_SETTINGS: '/revenue-settings/create',
    GET_REVENUE_SETTINGS_BY_ID: '/revenue-settings/get-revenue-settings-by-client-id/',
    UPDATE_REVENUE_SETTINGS: '/revenue-settings/update',
    GET_ALL_DRAFTS: '/campaign/get-all-tem-camps',
    GET_POLICIES: '/policies/get/',
    UPDATE_POLICIES: '/policies/update',
    GET_CSV_DATA: '/others/get-export-data',
    GET_AFFILIATE_MARKETING_AVATARS: '/affiliated-marketing/get-joined-programs',
    GET_AFFILIATE_MARKETING_BY_ID: '/affiliated-marketing/get-program-detail-by-id',
    ADD_FONTS: '/fonts/add',
    GET_FONTS: '/fonts/get',
    GET_AFFILIATE_MARKETING_TRANSACTIONS: '/affiliated-transactions/get-all',
    GET_REVENUE_STATS_BY_CAMPAIGN: '/stats/get-revenue-status-by-campaign',
    GET_REVENUE_DATA_FOR_REPORT: '/reports/revenue-stats',
    GET_CLIENT_DATA_FOR_REPORT: '/reports/clients-count',
    GET_FAN_DATA_FOR_REPORT: '/reports/fans-count',
    GET_VIEWERS_DATA_FOR_REPORT: '/reports/viewers-count',
    GET_SHARES_DATA_FOR_REPORT: '/reports/shares-count',
    GET_SIGNUP_DATA_FOR_REPORT: '/reports/sign-up-count',
    GET_CAMPAIGNS_FOR_REPORT: '/reports/campaigns',
    GET_ALL_ACTIVE_PLANS: '/subscriptions/get-active-plans',
    CREATE_SUBSCRIPTION: '/subscriptions/create-subscription',
    GET_USER_SUBS_BY_ID: '/subscriptions/get-sub-by-user-id',
    CONFIRM_SUBSCRIPTION: '/subscriptions/confirm-subscription'
};

export const variables = {
    token: 'auth_token'
};
