import types from '../types/types';

//create campaign
export const getCampaign = (params) => {
    return {
        type: types.GET_CAMPAIGN_REQUEST,
        payload: params
    };
};

export const clearGetCampaign = () => {
    return {
        type: types.GET_CAMPAIGN_UPDATE,
    };
};


// //campaign states
// export const updateCampaignStates = (params) => {
//     return {
//         type: types.UPDATE_CAMPAIGN_STATES,
//         payload: params
//     };
// };
//
// export const updateCampaignStatesRewards = (params) => {
//     return {
//         type: types.UPDATE_CAMPAIGN_STATES_REWARDS,
//         payload: params
//     };
// };
//
//
// //campaign states
// export const emptyUpdateStates = () => {
//     return {
//         type: types.EMPTY_CAMPAIGN_STATES
//     };
// };
//
// export const emptyCreateStates = () => {
//     return {
//         type: types.CREATE_CAMPAIGN_UPDATE
//     };
// };






