import { takeLatest, all, call, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls, variables } from '../lib/urls';
import Api from '../lib/request-service';
import { notification } from 'antd';

export default function* AddAminRoleSaga() {
    yield takeLatest(types.ADD_ADMIN_ROLE_REQUEST, AddAdminRoleSagaApi);
}
function* AddAdminRoleSagaApi(params) {
    console.log(params);
    let param = {
        name: params.name,
        role_type: params.role_type,
        permissions: {...params.permission}
    };
    let history = params.history;

    try {
        const response = yield Api.post(`${api_urls.ADMIN_ADD_ROLE}`, param, true);
        if (response.status === true) {
            yield put({ type: types.ADD_ADMIN_ROLE_SUCCESS, payload: response });
            // notification success

            notification.success({
                placement: 'topRight',
                message: response.message
            });
        } else {
            yield put({ type: types.ADD_ADMIN_ROLE_FAILURE, error: response });
            // notification error res.message
            notification.error({
                placement: 'topRight',
                message: response.message
            });
        }
    } catch (error) {
        yield put({ type: types.ADD_ADMIN_ROLE_FAILURE, error: error });
        // notification error error.message
        notification.error({
            placement: 'topRight',
            message: error.message
        });
    }
}
