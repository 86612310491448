import React, { useState, useEffect } from 'react';
import { Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { TextInput, ValidationForm } from 'react-bootstrap4-form-validation';
import validator from 'validator';
import { passwordGenerator } from '../../utils/passwordGenerator';
import { useDispatch } from 'react-redux';
import { doAddAdmin } from '../../store/actions/user.actions';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import constants from '../../constants/constants';
import Api from '../../store/lib/request-service';
import { api_urls } from '../../store/lib/urls';
import { notification } from 'antd';
import { Refresh } from 'iconsax-react';


const choices = [
    { label: 'Super Admin', value: '1' },
    { label: 'Admin', value: '2' },
    { label: 'Account Manager', value: '3' }
];

const AddAdmin = () => {
    const [eye, setEye] = useState(true);
    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        roles: {
            _id: '',
            label: '',
            value: ''
        },
        user_type: ['ADMIN']
    });
    const [adminRoles, setAdminRoles] = useState([]);
    const [suggestedPassword, setSuggestedPassword] = useState(passwordGenerator());
    const [copiedText, setCopiedText] = useState(false);
    const [showSuggestedPassword, setShowSuggestedPassword] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    // const sp = () => {};
    const handleChange = (e) => {
        if (e.target.name === 'email') setData({ ...data, [e.target.name]: e.target.value.toLowerCase().trim() });
        else setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleChangeRole = (e) => {
        // console.log('role data',{ ...data, roles: {label:e.name,value:e._id} })
        setData((data) => ({ ...data, roles: e }));
    };

    const handleSubmit = async (e, formData, inputs) => {
        e.preventDefault();

        if (
            data?.email?.length === 0 ||
            data?.first_name?.length === 0 ||
            data?.last_name?.length === 0 ||
            data?.password?.length === 0 ||
            data?.roles?.value?.length === 0
        ) {
            notification.error({
                placement: 'topRight',
                message: 'All fields are required'
            });
        } else {
            let availability = await checkAvailability(data.email.toLowerCase().trim());
            if (availability) {
                dispatch(doAddAdmin({ data, history }));
                console.log(data);
            }
        }
    };

    const handleErrorSubmit = (e, formData, errorInputs) => {
        //console.log(errorInputs);
    };

    const checkAvailability = async (e) => {
        let body = {
            key: 'email',
            value: e
        };
        const response = await Api.post(api_urls.CHECK_AVAILABILITY, body, true);
        if (response.status) {
            // setError(true)
            notification.error({
                placement: 'topRight',
                message: 'Email Address is already in use!',
                duration: 2.8
            });
        }
        return !response.status;
    };

    const fetchRoles = async () => {
        let response = await Api.get(api_urls.GET_ALL_ROLES_FOR_DROPDOWN, true);
        if (response) {
            let roleArr = response.data.slice(1);
            setAdminRoles(roleArr);
        }
        // fetch(`${process.env.REACT_APP_API_URL}/admin/roles/get-all-roles-for-dropdown`, true)
        // .then((res)=>res.json())
        // .then((res)=>{
        //     setAdminRoles(res.data);

        // })
    };

    const onEyePassword = () => {
        setEye(!eye);
    };

    const generateRandomPassword = () => {
        let password = passwordGenerator();
        setSuggestedPassword(password);
    };

    const handleSuggestedPassword = async () => {
        console.log('suggested password:: ', suggestedPassword);
        navigator.clipboard.writeText(suggestedPassword);
        setCopiedText(true);
        setTimeout(() => {
            setCopiedText(false);
        }, 5000);
    };

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: state.isFocused ? 'white' : 'none',
            // Overwrittes the different states of border
            borderColor: state.isFocused ? '#d36011' : 'block',
            borderRadius: '0',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            '&:hover': {
                // Overwrittes the different states of border
                borderColor: state.isFocused ? '#d36011' : '#000000'
            }
        })
    };

    useEffect(() => {
        // dispatch(updateStateForAddClient());
        fetchRoles();
    }, []);
    // console.log(adminRoles,"Roles from DB", )
    return (
        <Card className="add_admin">
            <Card.Header>
                <Card.Title as="h5" className="">
                    Add User
                </Card.Title>
            </Card.Header>
            <Card.Body className="mb-5">
                <Row className="justify-content-center">
                    <Col sm={10} xl={6}>
                        <div className="admin-profile p-4">
                            <ValidationForm onSubmit={handleSubmit} onErrorSubmit={handleErrorSubmit}>
                                <div className="form-group fill row">
                                    <div className="col-md-6 mb-4">
                                        <label htmlFor="name">
                                            First Name<span className="required">*</span>
                                        </label>
                                        <TextInput
                                            name="first_name"
                                            id="first_name"
                                            type="text"
                                            className="form-control"
                                            placeholder="First Name"
                                            required
                                            value={data?.first_name}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            errorMessage={{ minLength: 'First name should be between 1 and 15 characters' }}
                                            minLength="1"
                                            maxLength="15"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="name">
                                            Last Name<span className="required">*</span>
                                        </label>
                                        <TextInput
                                            name="last_name"
                                            id="last_name"
                                            type="text"
                                            className="form-control"
                                            placeholder="Last Name"
                                            required
                                            value={data?.last_name}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            errorMessage={{ minLength: 'Last name should be between 1 and 15 characters' }}
                                            minLength="1"
                                            maxLength="15"
                                        />
                                    </div>
                                </div>
                                <div className="form-group fill">
                                    <label htmlFor="email">
                                        Email<span className="required">*</span>
                                    </label>
                                    <TextInput
                                        name="email"
                                        id="email"
                                        type="email"
                                        className="form-control"
                                        placeholder="Email Address"
                                        required
                                        validator={validator.isEmail}
                                        errorMessage={{ validator: 'Please enter a valid email' }}
                                        value={data.email}
                                        onChange={handleChange}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="form-group fill">
                                    <label htmlFor="password">
                                        Password<span className="required">*</span>
                                    </label>
                                    <TextInput
                                        name="password"
                                        id="password"
                                        type={eye ? 'password' : 'text'}
                                        className="form-control form-pw"
                                        placeholder="Enter Password"
                                        required
                                        onFocus={() => setShowSuggestedPassword(true)}
                                        // validator={validator.isEmail}
                                        // pattern="^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
                                        pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@#$%^&(){}:;<>,.?/~_+-]).{8,}$"
                                        errorMessage={{
                                            required: constants.passwordRequired,
                                            pattern: constants.passwordValidationMessage
                                        }}
                                        value={data.password}
                                        onChange={handleChange}
                                        autoComplete="off"
                                    />
                                    {
                                        showSuggestedPassword && (
                                            <div className="mt-2 password-suggestion">
                                                <div className="p-1">
                                                    <OverlayTrigger
                                                        overlay={
                                                            <Tooltip id="tooltip-disabled">
                                                                <small>Copy password</small>
                                                            </Tooltip>
                                                        }
                                                        placement="bottom"
                                                    >
                                                        <span
                                                            className="mx-1"
                                                            style={{ cursor: 'pointer', color: '#000' }}
                                                            onClick={handleSuggestedPassword}
                                                        >
                                                            {suggestedPassword}
                                                        </span>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        overlay={
                                                            <Tooltip id="tooltip-disabled">
                                                                <small>Regenerate suggested password</small>
                                                            </Tooltip>
                                                        }
                                                        placement="bottom"
                                                    >
                                                        {/* <i className='feather icon-refresh-cw font-weight-bold' style={{ cursor: 'pointer' }} onClick={generateRandomPassword} /> */}
                                                        <Refresh
                                                            size="17"
                                                            color="#f18524"
                                                            variant="Broken"
                                                            className="font-weight-bold float-right"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={generateRandomPassword}
                                                        />
                                                    </OverlayTrigger>
                                                </div>
                                                <div className="p-1 d-inline-block password-suggestion-heading position-relative">
                                                    Suggested Password
                                                </div>
                                                {copiedText && <span className="text-danger font-weight-light ml-4">Copied!</span>}
                                            </div>
                                        )

                                        // (
                                        //     <small className="mt-2">
                                        //         <span className="font-weight-bold">Use suggested password:</span>
                                        //         <OverlayTrigger
                                        //             overlay={
                                        //                 <Tooltip id="tooltip-disabled">
                                        //                     <small>Copy password</small>
                                        //                 </Tooltip>
                                        //             }
                                        //             placement="bottom"
                                        //         >
                                        //             <span className="mx-3" style={{ cursor: 'pointer' }} onClick={handleSuggestedPassword}>
                                        //                 {suggestedPassword}
                                        //             </span>
                                        //         </OverlayTrigger>
                                        //         <OverlayTrigger
                                        //             overlay={
                                        //                 <Tooltip id="tooltip-disabled">
                                        //                     <small>Regenerate suggested password</small>
                                        //                 </Tooltip>
                                        //             }
                                        //             placement="bottom"
                                        //         >
                                        //             <i
                                        //                 className="feather icon-refresh-cw font-weight-bold"
                                        //                 style={{ cursor: 'pointer' }}
                                        //                 onClick={generateRandomPassword}
                                        //             />
                                        //         </OverlayTrigger>
                                        //         {copiedText && <span className="text-danger font-weight-light mx-3">Copied!</span>}
                                        //     </small>
                                        // )
                                    }
                                    {eye ? (
                                        <i className="fa fa-solid fa-eye-slash eye_position" onClick={onEyePassword} />
                                    ) : (
                                        <i className="fa fa-solid fa-eye eye_position" onClick={onEyePassword} />
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>
                                        Role<span className="required">*</span>
                                    </label>
                                    <Select options={adminRoles} onClick={fetchRoles} styles={customStyles} onChange={handleChangeRole} />
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn_primary">Add User</button>
                                </div>
                            </ValidationForm>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default AddAdmin;
