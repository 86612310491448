import types from '../types/types';
const initialState = {
    status: false,
    message: null,
    error: null,
    data: null,
    loading: false,
};

const requestState = {
    ...initialState,
    loading: true,
};
const update_campaign = (state = initialState, action) => {
    switch (action.type) {
        case types.EDIT_CAMPAIGN_REQUEST:
            return {
                ...state,
                ...requestState,
            };
        case types.EDIT_CAMPAIGN_SUCCESS:
            return {
                ...state,
                status: true,
                message: 'SUCCESS',
                error: null,
                data: action.payload,
                loading: false,
            };
        case types.EDIT_CAMPAIGN_FAILURE:
            return {
                ...state,
                status: action.error.status,
                message: action.error.message,
                error: action.error,
                data: null,
                loading: false,
            };
        case types.EDIT_CAMPAIGN_UPDATE:
            return {
                ...initialState,
                status: false,
                message: null,
                error: null,
                data: null,
                loading: false,
            };
        case types.SIGN_IN_UPDATE:
            return {
                ...state,
                status: null,
                message: null,
                error: null,
                data: null,
                loading: false
            };
        default:
            return state;
    }
};

export default update_campaign;
