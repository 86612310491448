import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App/index';
import { CookiesProvider } from 'react-cookie'
import * as serviceWorker from './serviceWorker';
import { store } from './store/store';
import config from './config';
import './assets/scss/style.scss';
import './assets/css/custom.css';
import './assets/css/shomeel.css';
import './assets/css/templates.css';
import './assets/css/skeleton.css';
import './assets/css/responsive.css';
import './assets/iconsax-css/style.css'
const app = (
    <React.StrictMode>
        <BrowserRouter basename={config.basename}  getUserConfirmation={() => {
            /* Empty callback to block the default browser prompt */
        }}>
            <Provider store={store}>
                <CookiesProvider>
                    <App />
                </CookiesProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);
ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
