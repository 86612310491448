import * as React from 'react';
const MainComponent = React.lazy(() => import('./components/SubDomains/MainComponent'));
const ParamsPage = React.lazy(() => import('./components/SubDomains/ParamsPage'));
const Verification = React.lazy(() => import('./components/SubDomains/Verification'));
const ResetVerification = React.lazy(() => import('./components/SubDomains/ResetVerification'));
const GenericDemo = React.lazy(() => import('./components/StaticCampaign/Generic'));
const VideoDemo = React.lazy(() => import('./components/StaticCampaign/Video'));
const MusicClipDemo = React.lazy(() => import('./components/StaticCampaign/SoundClip'));
const HashTagDemo = React.lazy(() => import('./components/StaticCampaign/HashTag'));
const LeaderboardDemo = React.lazy(() => import('./components/StaticCampaign/Leaderboard'));
const UploadFileDemo = React.lazy(() => import('./components/StaticCampaign/UploadFile'));


const subDomainRoutes = [
    { path: '/', exact: true, name: 'Generic', component: MainComponent },
    { path: '/1', exact: true, name: 'Generic Demo', component: GenericDemo },
    { path: '/2', exact: true, name: 'Video Demo', component: VideoDemo },
    { path: '/3', exact: true, name: 'HashTag Demo', component: HashTagDemo },
    { path: '/4', exact: true, name: 'Leaderboard Demo', component: LeaderboardDemo },
    { path: '/5', exact: true, name: 'Music Clip Demo', component: MusicClipDemo },
    { path: '/6', exact: true, name: 'Upload File Demo', component: UploadFileDemo },
    { path: '/verification/:email/:otp', exact: true, name: 'Email Verification', component: Verification },
    { path: '/reset-password/:user/:otpReset', exact: true, name: 'Reset Verification', component: ResetVerification },
    { path: '/:referral/:platform', exact: true, name: 'Generic', component: ParamsPage },
];
export default subDomainRoutes;
