import types from '../types/types';
const initialState = {
    user: {},
    error: '',
    data: null,
    isLoading: false
};
const logoutReducer = (state = initialState, action) => {
    switch (action.type) {
        // Logout
        case types.LOGOUT_REQUEST:
            return {
                ...state
            };
        case types.LOGOUT_SUCCESS:
            return {
                ...state,
                status: 200,
                message: 'SUCCESS',
                error: null,
                data: null,
                loading: false
            };
        case types.LOGOUT_FAILURE:
            return {
                ...state,
                status: action.error.status,
                message: action.error.message,
                error: action.error,
                data: null,
                loading: false
            };
        case types.LOGOUT_UPDATE:
            return {
                ...state,
                status: null,
                message: null,
                error: null,
                data: null,
                loading: false
            };
        case types.SIGN_IN_UPDATE:
            return {
                ...state,
                status: null,
                message: null,
                error: null,
                data: null,
                loading: false
            };
        default: {
            return state;
        }
    }
};
export default logoutReducer;