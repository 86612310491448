import { takeLatest, all, call, put } from 'redux-saga/effects'
import { SIGN_IN, FORGET_PASSWORD } from '../types/user.type'
import types from '../types/types';
import {api_urls, variables} from '../lib/urls';
import session from '../lib/session'
import { doSignIn, doForgetPassword } from '../service/user.service'
import { defaultActionSuccess,
defaultActionFailure,
defaultLoadingAction
} from "../app.actions";
import Api from '../lib/request-service';
import { SIGN_IN_API } from '../api';
import { notification } from 'antd';

export default function* SignInSaga() {
    yield takeLatest(types.SIGN_IN_REQUEST, SignInSagaApi);
}
function* SignInSagaApi(params) {

    let param = params.payload;
    let history = params.history;

    try {
        const response = yield Api.post(`${api_urls.SIGN_IN}`, param,true);
        if (response.status === true) {
            yield put({type: types.SIGN_IN_SUCCESS, payload: response});
            session.setToken(response?.data?.token)
            session.setUser(response?.data?.user)
            // notification success
            notification.success({
                placement: "topRight",
                message: response.message,
                duration: '1'
            });
            // redirect
            history.push('/overview');
        } else {
            yield put({type: types.SIGN_IN_FAILURE, error: response});
            // not error res.message
            notification.error({
                placement: "topRight",
                message: response.message,
                duration: '1'
            });
        }
    } catch (error) {
        yield put({type: types.SIGN_IN_FAILURE, error: error});
        // not error error.message
        notification.error({
            placement: "topRight",
            // message: err.message,
            message: "Wrong Password or Email",
            maxCount: '1',
            duration: '1'
        });
    }
}

// export default function* signIn() {
//     yield takeLatest("SIGN_IN", signInApi);
//   }
//   function* signInApi({params}) {
//       console.log("check at saga" , params)
//     try {
//       const response = yield Api.post(`${SIGN_IN_API}`, params, false);
//       if(response.status === true) {
//         yield put({type: SIGN_IN.SUCCESS, payload: response});
//       }else{
//         yield put({type: SIGN_IN.ERROR, error: response});
//       }
//     } catch (error) {
//       yield put({type: SIGN_IN.ERROR, error: error});
//     }
//   }

// Approach 2
// function* doSignInSaga({params, history}){

//     try {
//         yield put(defaultLoadingAction(SIGN_IN));
//         let response = yield call(doSignIn, params);

//         if(!response.status) {

//             yield put(defaultActionFailure(SIGN_IN, response));
//             notification.error({
//                 placement: "topRight",
//                 message: response.message,
//             });
//             console.log("Token not received line 48");
//         }else{
//             const updatedUser = {
//                 ...response.payload,
//             };

//             yield put(defaultActionSuccess(SIGN_IN, response));
//             console.log(response , "Success");
//             history.push('/overview');
//             notification.success({
//                 placement: "topRight",
//                 message: response.message,
//             });

//         }

//     }catch (err) {
//         yield put(defaultActionFailure(SIGN_IN, err));
//         notification.error({
//             placement: "topRight",
//             // message: err.message,
//             message: "Wrong Password or Email"
//         });
//     }
// }
// // Forget Password
// function* doForgetPasswordSaga({params}){

//     try {
//         yield put(defaultLoadingAction(FORGET_PASSWORD));
//         let response = yield call(doForgetPassword, params);

//         if(response.message) {


//             yield put(defaultActionFailure(FORGET_PASSWORD, response));

//         }else{
//             const updatedUser = {
//                 ...response.payload,
//             };
//             yield put(defaultActionSuccess(FORGET_PASSWORD, response));
//             console.log(response , "Success");

//             notification.success({
//                 placement: "topRight",
//                 message: "Check your Email for Password Reset Link",
//             });

//         }

//     }catch (err) {
//         yield put(defaultActionFailure(FORGET_PASSWORD, err));
//         notification.error({
//             placement: "topRight",
//             message: "This Email is not registered to any account",
//         });
//     }
// }

// export default function* () {
//     yield all([
//         takeLatest(SIGN_IN.SIMPLE, doSignInSaga),
//         takeLatest(FORGET_PASSWORD.SIMPLE, doForgetPasswordSaga)
//     ]);
// }
