import { takeLatest, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls } from '../lib/urls';
import Api from '../lib/request-service';
import { notification } from 'antd';

export default function* OTPValidationSaga() {
    yield takeLatest(types.OTP_VALIDATION_REQUEST, OTPValidationSagaApi);
}
function* OTPValidationSagaApi(params) {
    // console.log(params);
    let param = { email: params.user, otp: params.otp };
    // let history = params.history;
    try {
        const response = yield Api.post(`${api_urls.OTP_VALIDATION}`, param, true);
        if (response.status === true) {
            yield put({ type: types.OTP_VALIDATION_SUCCESS, payload: response.data });
            // notification success
            notification.success({
                placement: 'topRight',
                message: response.message
            });
        } else {
            yield put({ type: types.OTP_VALIDATION_FAILURE, error: response });
            // notification error res.message
            notification.error({
                placement: 'topRight',
                message: 'OTP has expired'
            });
        }
    } catch (error) {
        yield put({ type: types.OTP_VALIDATION_FAILURE, error: error });
        // not error error.message
        notification.error({
            placement: 'topRight',
            message: 'error'
        });
    }
}
