import { takeLatest, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls } from '../lib/urls';
import Api from '../lib/request-service';

export default function* DeleteAdminSaga() {
    yield takeLatest(types.DEL_ADMIN_REQUEST, DelAdminSagaApi);
}
function* DelAdminSagaApi(params) {
    let param = { id: params.params };

    try {
        const response = yield Api.delete(`${api_urls.DEL_ADMIN}`, param, true);

        yield put({ type: types.DEL_ADMIN_SUCCESS, payload: response });
        // notification success

        yield put({ type: types.DEL_ADMIN_FAILURE, error: response });
        // notification error res.message
    } catch (error) {
        yield put({ type: types.DEL_ADMIN_FAILURE, error: error });
        // notification error error.message
    }
}
