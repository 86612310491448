import { io } from "socket.io-client";
import CONSTANTS from '../constants/constants';
import PubSub from 'pubsub-js'

const getUri = () =>{
    if(window.location?.hostname?.includes('qa')){
        return process.env.REACT_APP_API_SOCKET_QA
    }else if(window.location?.hostname?.includes('stg')){
        return process.env.REACT_APP_API_SOCKET_STG
    }else if(window.location?.hostname?.includes('dev')){
        return process.env.REACT_APP_API_SOCKET_DEV
    }else{
        return process.env.REACT_APP_API_SOCKET_LOCAL
    }
};

const socket = io(getUri(),{ transports: ['websocket'] });


function registerUser(name, data) {
    // socket.on(name, (data) => cb(data));
    socket.emit(name, data);
}



function notificationFunction() {
    socket.on('notification', (response) => {
        console.log(response, "response socket");
        if (response.event) {
            PubSub.publishSync(CONSTANTS.NOTIFICATION_TYPE.NOTIFICATION, response.data);
        }
        // if (response.event === CONSTANTS.NOTIFICATION_TYPE.FOLLOW) {
        //     console.log(JSON.stringify(response.event === CONSTANTS.NOTIFICATION_TYPE.FOLLOW),"response event");
        //     PubSub.publishSync(response?.event, response.data);
        // } else if (response.event === CONSTANTS.NOTIFICATION_TYPE.USER_BLOCK) {
        //     console.log(response);
        //     PubSub.publishSync(response?.event, response?.data);
        // } else if (response.event === CONSTANTS.NOTIFICATION_TYPE.CAMPAIGN_CREATE) {
        //     console.log(response);
        //     PubSub.publishSync(response?.event, response?.data);
        // }else if (response.event === CONSTANTS.NOTIFICATION_TYPE.SHARE_GOAL_ACHIEVED) {
        //     console.log(response);
        //     PubSub.publishSync(response?.event, response?.data);
        // }else if (response.event === CONSTANTS.NOTIFICATION_TYPE.CAMPAIGN_CREATE_REVIEW) {
        //     console.log(response);
        //     PubSub.publishSync(response?.event, response?.data);
        // }else if (response.event === CONSTANTS.NOTIFICATION_TYPE.USER_SIGNUP) {
        //     console.log(response);
        //     PubSub.publishSync(response?.event, response?.data);
        // }  else if (response.event === 'error') {
        //     //  console.log('this is an error', response.data);
        // }
    });
}

export {
    registerUser,
    notificationFunction,
};

