// import { fork } from 'child_process';
import { all, fork } from 'redux-saga/effects';
import SignInSaga from './user.sagas';

import createCampaign from './createCampaignSaga';
import ForgotPasswordSaga from './forgotPasswordSaga';
import ResetPasswordSaga from './resetPasswordSagas';
import ChangePasswordSaga from './changePasswordSaga';
import AddAdminSaga from './addAdminSaga';
import AddClientSaga from './addClientSaga';
import GetAllClientsSaga from './getAllClientsSaga';
import GetAllFansSaga from './getAllFansSaga';
import GetAllAdminsSaga from './getAllAdminSagas';
import DeleteAdminSaga from './deleteAdminSaga';
import LogoutSaga from './logoutSaga';
import GetUserByIdSaga from './getUserById';
import ViewUserByIdSaga from './viewUserById';
import UpdateAdminSaga from './updateAdminSagas'
import UpdateUserSaga from './updateUserSagas';
import AddAminRoleSaga from './addRoleSaga';
import OTPValidationSaga from './otpValidationSaga';
import getCampaignByDomain from './getCampainByDomain';
import DomainSignUpSaga from './domainAuthSaga';
import SignInDomainSaga from './signInDomainSaga';
import getCampaignById from './getCampainById'
import updateCampaignSaga from './updateCampaignSaga';
export default function* rootSaga() {
    yield all([
        fork(SignInSaga),
        fork(ForgotPasswordSaga),
        fork(ResetPasswordSaga),
        fork(ChangePasswordSaga),
        fork(createCampaign),
        fork(AddAdminSaga),
        fork(AddClientSaga),
        fork(GetAllClientsSaga),
        fork(GetAllFansSaga),
        fork(GetAllAdminsSaga),
        fork(DeleteAdminSaga),
        fork(LogoutSaga),
        fork(GetUserByIdSaga),
        fork(ViewUserByIdSaga),
        fork(UpdateAdminSaga),
        fork(UpdateUserSaga),
        fork(AddAminRoleSaga),
        fork(OTPValidationSaga),
        fork(getCampaignByDomain),
        fork(DomainSignUpSaga),
        fork(SignInDomainSaga),
        fork(getCampaignById),
        fork(updateCampaignSaga),
    ]);
}
