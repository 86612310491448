import types from '../types/types';
const initialState = {
    status: null,
    message: null,
    error: null,
    data: null,
    loading: true
};

const requestState = {
    ...initialState,
    loading: true
};
const otp_validation = (state = initialState, action) => {
    switch (action.type) {
        case types.OTP_VALIDATION_REQUEST:
            return {
                ...state,
                ...requestState
            };
        case types.OTP_VALIDATION_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        case types.OTP_VALIDATION_FAILURE:
            return {
                ...state,
                status: action.error.status,
                message: action.error.message,
                error: action.error,
                data: null,
                loading: false
            };
        case types.OTP_VALIDATION_UPDATE:
            return {
                ...initialState,
                status: null,
                message: null,
                error: null,
                data: null,
                loading: false
            };
        case types.SIGN_IN_UPDATE:
            return {
                ...state,
                status: null,
                message: null,
                error: null,
                data: null,
                loading: false
            };
        default:
            return state;
    }
};

export default otp_validation;
